<template>
	<w-layout
		v-show="chatOpened"
		id="support-chat"
		class="support-chat-zone"
		:style="mainStyle"
		column
		:ma-5="$vuetify.breakpoint.smAndUp"
		:pa-5="$vuetify.breakpoint.smAndUp"
	>
		<w-flex shrink class="primary elevation-1 support-chat-toolbar">
			<w-layout row justify-center align-center>
				<w-flex ma-2 shrink>
					<v-avatar tile>
						<v-img :src="logo" contain class="support-chat-logo" />
					</v-avatar>
				</w-flex>
				<w-flex v-t="'support'" class="text-xs-left white--text" shrink title mx-2></w-flex>
				<v-spacer></v-spacer>
				<v-btn flat icon @click="closeChat">
					<v-icon tile class="white--text">close</v-icon>
				</v-btn>
			</w-layout>
		</w-flex>
		<w-flex grow class="elevation-1 support-chat-content" pa-0>
			<w-layout id="hubspot-zone" justify-center align-center> </w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import AuthModuleGuard from '@/mixins/ModulesGuards/Auth/AuthModuleGuard'
/* eslint-disable */
export default {
	name: 'SupportChat',
	mixins: [AuthModuleGuard],
	data: function () {
		return {
			chatOpened: false,
			hsScript: null,
			token: null,
		}
	},
	computed: {
		...mapState({
			user: state => state.user.data,
			email: state => state.user.email,
			lastname: state => state.user.lastname,
			firstname: state => state.user.firstname,
			logo: state => state.whitelabel.selected.logo
		}),
		isAuthenticated: function () {
			return this.service.getIsAuthenticated()
		},
		mainStyle: function () {
			let result = 'min-width: 300px !important; min-height: 450px !important;'
			if (this.$vuetify.breakpoint.xs) {
				result += 'width: 100vw !important; height: 100vh !important;'
			} else if (this.$vuetify.breakpoint.sm) {
				result += 'margin-right: 5px !important; margin-bottom: 5px !important; width: 100vw !important; height: 100vh !important;'
			} else if (this.$vuetify.breakpoint.md) {
				result += 'margin-right: 10px !important; margin-bottom: 10px !important; width: 66vw !important; height: 80vh !important;'
			} else if (this.$vuetify.breakpoint.lgAndUp) {
				result += 'margin-right: 20px !important; margin-bottom: 20px !important; width: 33vw !important; height: 75vh !important;'
			} else {
				result += 'width: 100vw !important; height: 100vh !important;'
			}
			return result
		},
		chatflowId: function () {
			return this.$envVar.VUE_APP_HUBSPOT_ACTIVE_CHATFLOW_ID[this.$i18n.locale]
				? this.$envVar.VUE_APP_HUBSPOT_ACTIVE_CHATFLOW_ID[this.$i18n.locale]
				: this.$envVar.VUE_APP_HUBSPOT_ACTIVE_CHATFLOW_ID['fr']
		},
	},
	watch: {
		user: {
			handler: function () {
				this.setToken()
			},
			immediate: true,
			deep: true
		},
		isAuthenticated: {
			handler: function () {
				this.setToken()
			},
			immediate: true
		},
		token: {
			handler: function () {
				this.updateChat()
			},
			immediate: true
		}
	},
	created: function () {
		if (!this.conditionalDisplay.isMobileApp) {
			this.initChat()
		}
	},
	destroyed: function () {
		if (!this.conditionalDisplay.isMobileApp) {
			this.destroyChat()
		}
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [{ event: this.appEvents.TOGGLE_SUPPORT_CHAT, action: this.toggleChat }]
		},
		initChat: function () {
			window.hsConversationsSettings = {
				loadImmediately: true,
				enableWidgetCookieBanner: false,
				disableAttachment: false,
				inlineEmbedSelector: '#hubspot-zone'
			}
			this.hsScript = document.createElement('script')
			this.hsScript.type = 'text/javascript'
			this.hsScript.id = 'hs-script-loader'
			this.hsScript.async = true
			this.hsScript.defer = true
			this.hsScript.src = '//js-na1.hs-scripts.com/4020008.js'
			document.body.appendChild(this.hsScript)
		},
		destroyChat: function () {
			if (window.HubSpotConversations) {
				window.HubSpotConversations.widget.remove()
			} else {
				window.hsConversationsOnReady?.push(function () {
					window.HubSpotConversations.widget.remove()
				})
			}
			if (window.hsConversationsSettings) {
				delete window.hsConversationsSettings
			}
			if (window.HubSpotConversations) {
				delete window.HubSpotConversations
			}
			if (this.hsScript) {
				document.body.removeChild(this.hsScript)
			}
		},
		setToken: function () {
			let result
			if (this.isAuthenticated && this.email && this.lastname && this.firstname) {
				result = this.appService
					.getHubspotToken(this.email, this.lastname, this.firstname)
					.then(res => (res.hasOwnProperty('token') ? (this.token = res.token) : (this.token = null)))
			} else {
				result = new Promise(resolve => resolve((this.token = null)))
			}
			return result
		},
		toggleChat: function () {
			if (this.chatOpened) {
				this.closeChat()
			} else {
				window.history.pushState({}, 'activeflow', `?activeflow=${this.chatflowId}`)
				this.openChat()
			}
		},
		openChat: function () {
			return new Promise(resolve => {
				if (!window.hsConversationsSettings) {
					window.hsConversationsSettings = {
						loadImmediately: true,
						enableWidgetCookieBanner: false,
						disableAttachment: false,
						inlineEmbedSelector: '#hubspot-zone'
					}
				}
				if (this.isAuthenticated && this.email && this.token) {
					window.hsConversationsSettings.identificationEmail = this.email
					window.hsConversationsSettings.identificationToken = this.token
				} else {
					window.hsConversationsSettings.identificationEmail = ''
					window.hsConversationsSettings.identificationToken = ''
				}
				if (window.HubSpotConversations) {
					window.HubSpotConversations.resetAndReloadWidget()
					this.chatOpened = true
					resolve()
				} else {
					window.hsConversationsOnReady?.push(() => {
						window.HubSpotConversations.resetAndReloadWidget()
						this.chatOpened = true
						resolve()
					})
				}
			})
		},
		closeChat: function () {
			return new Promise(resolve => {
				this.chatOpened = false
				if (window.HubSpotConversations) {
					window.HubSpotConversations.widget.close()
					resolve()
				} else {
					window.hsConversationsOnReady?.push(() => {
						window.HubSpotConversations.widget.close()
						resolve()
					})
				}
			})
		},
		updateChat: function () {
			if (this.chatOpened) {
				this.openChat()
			}
		}
	}
}
</script>

<style>
.support-chat-zone {
	position: fixed !important;
	bottom: 0;
	right: 0;
	z-index: 5;
}
.support-chat-toolbar {
	min-height: 71px !important;
	border-radius: 7px 7px 0 0;
	z-index: 2;
}
.support-chat-logo {
	max-width: 100%;
	max-height: 100%;
}
.support-chat-content {
	border-bottom-color: var(--v-primary-base) !important;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-left-color: var(--v-primary-base) !important;
	border-left-width: 1px;
	border-left-style: solid;
	border-right-color: var(--v-primary-base) !important;
	border-right-width: 1px;
	border-right-style: solid;
	background-color: grey !important;
}
#hubspot-zone {
	height: calc(100% + 68px) !important;
	transform: translate(0, -68px);
}
#hubspot-conversations-inline-iframe {
	height: 100%;
	width: 100%;
	border: none;
}
#hubspot-conversations-inline-parent {
	height: 100%;
	width: 100%;
	border: none;
}
</style>
